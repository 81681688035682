import React, { useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import _ from 'lodash'
import Layout from '../../components/Layout'
import * as styles from './contentPage.module.css'

export default function ContentPage({ pageContext, data }) {

    const { markdownRemark } = data
    const { frontmatter: { title, description }, html, excerpt, fields } = markdownRemark

    return (
        <Layout
            sectionName=""
            seoUrl={pageContext.slug}
            seoDescription={description || excerpt}
            seoTitle={`${title} | Kubevious.io`}
            seoType="article"
        >
            <div className={styles.contentContainer}
                dangerouslySetInnerHTML={{ __html: html }}
            />
        </Layout>
    )
}


export const pageQuery = graphql`
    query($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            frontmatter {
                title
                description
            }
            fields {
                slug
            }
        }
    }
`
